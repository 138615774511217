.iconMb {
  margin-bottom: 40px;
  @media(max-width: 992px) {
    margin-bottom: 32px;
  }
}

.textMb {
  margin-bottom: 63px;
  @media(max-width: 992px) {
    margin-bottom: 40px;
  }
}

.nextButtonContent {
  color: white;
  padding-right: 8px;
}

.layoutHeight {
  padding-top: 30vh
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
}